import { cloneDeep, isNil, isEmpty } from 'lodash'
import { Model, Collection } from 'models/base'
import { PRICE_RFP_RESPONSE } from 'data/guided-purchase-price-calcs'
import { POOL_ISO, POOL_REC } from 'data/guided-purchase-pools'
import { config as API } from 'api'
import { getContentRangeTotal } from 'utils/headers'
import * as postgrest from 'services/postgrest'

const GPSS_ENDPOINT = API.GUIDED_PURCHASE_ALL.PATH
const USAGE_FILTER_ENDPOINT = API.GUIDED_PURCHASE_USAGE_FILTER_ENDPOINT.PATH
const REC_FILTER_ENDPOINT = API.GUIDED_PURCHASE_REC_FILTER_ENDPOINT.PATH
const FILTER_GPSS_ENDPOINT = API.GUIDED_PURCHASES_FILTER_GPSS.PATH
const COLLECTION_NAME = 'GUIDED_PURCHASES'
const MODEL_NAME = 'GUIDED_PURCHASE'
export const MODULE_NAME = 'guidedPurchases' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 10

const DEFAULT_FIELDS = {
	filters: {
		[POOL_ISO]: [],
		[POOL_REC]: [],
	},
	is_eaches: false,
	price_calc: PRICE_RFP_RESPONSE,
	purchase_sheet_id: null,
	relator_groups: [1],
	partition_selections: {},
	partitions: [],
	selected: {},
	selectedDataset: null,
	purchase_dataset_id: null,
}

const initialState = {
	filterOptions: {},
	gpsListViewFilters: [],
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: cloneDeep(DEFAULT_FIELDS),
	currentModel: new Model(DEFAULT_FIELDS),
	results: new Collection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE,
	isFetchingCollaborators: true,
	collaborators: [],
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FETCHED_FILTER_OPTIONS_AND_ANALYSES = `${MODEL_NAME}_FETCHED_FILTER_OPTIONS_AND_ANALYSES`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const SET_DATA = `${MODEL_NAME}_SET_DATA`
const CREATED = `${MODEL_NAME}_CREATED`
const SAVED = `${MODEL_NAME}_SAVED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const PRODUCT_EDIT_CREATED = `${MODEL_NAME}_PRODUCT_EDIT_CREATED`
const FAILED = `${COLLECTION_NAME}_FAILED`
const COLLABORATOR_REQUESTED = 'COLLABORATOR_REQUESTED'
const COLLABORATOR_FETCHED = 'COLLABORATOR_FETCHED'

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
const receive = (action, fields) => ({ type: action, payload: fields })
export const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults },
})
const setData = (fields) => ({ type: SET_DATA, payload: fields })
const saveEdit = (fields) => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = (fields) => ({ type: EDIT_CLEARED })
const fail = (error) => ({ type: FAILED, payload: error })

const setVals = (selected, selecting) => {
	if (selected.includes(selecting[0])) {
		selected.splice(selected.indexOf(selecting[0]), 1)
		return selected
	} else {
		return selected.concat(selecting)
	}
}

/**
 * Update GPS list view filters
 * thunk
 */
const updateGPSListViewFilter = (def_id, val, from, to, selected) => {
	return (dispatch, getState) => {
		let filtersCopy = []
		filtersCopy = cloneDeep(getState()[MODULE_NAME].gpsListViewFilters)

		if (!filtersCopy) {
			filtersCopy = []
		}
		// @todo update for ranges
		// @todo update for single "val"
		const vals = val ? [val] : null
		const existingFilter = filtersCopy.find(
			(filter) => filter.def_id === def_id,
		)

		if (existingFilter) {
			const index = filtersCopy.indexOf(existingFilter)
			if (vals && vals.length) {
				if (
					filtersCopy[index]['vals'].length === 1 &&
					filtersCopy[index]['vals'][0] === vals[0]
				) {
					filtersCopy.splice(index, 1)
				} else {
					filtersCopy[index]['vals'] = setVals(filtersCopy[index]['vals'], vals)
				}
			}
		} else {
			filtersCopy.push({
				def_id: def_id,
				vals: val ? [val] : null,
				from,
				to,
				selected,
			})
		}

		dispatch(fetchFilterOptionsAndAnalyses(filtersCopy))
	}
}

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array of the product filters module,
		// no need to fetch again. just set it as the currentModel
		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				// Temporary: need to remove this later
				const gpsData = existingModel.get('data') || {}
				existingModel.set(gpsData)

				return new Promise((resolve) => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		dispatch(request())

		return postgrest
			.fetch(GPSS_ENDPOINT, id)
			.then((response) => {
				if (!isNil(response) && !isEmpty(response)) {
					// Temporary: need to remove this later
					const tmpModel = {
						...response,
						...response.data,
					}
					dispatch(receive(FETCHED, tmpModel))
				} else {
					const error = new Error('Guided Purchase not found.')
					dispatch(fail(error))
					throw error
				}
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch resources using limit-offset pagination
 * thunk
 */
const fetchPage = (page) => {
	return (dispatch, getState) => {
		dispatch(request())

		const payload = {
			filters: [],
		}
		const { perPage } = getState()[MODULE_NAME]

		return postgrest
			.fetchPageWithPost(FILTER_GPSS_ENDPOINT, page, perPage, null, payload)
			.then((response) => {
				const res = response || {}
				const responseBody = res.body || []
				const totalResults = getContentRangeTotal(response.headers)
				dispatch(receivePage(responseBody, page, totalResults))
				return responseBody
			})
			.catch((error) => {
				dispatch(fail(error))
				// throw error
			})
	}
}

const fetchFilterOptionsAndAnalyses = (filters) => {
	return (dispatch, getState) => {
		dispatch(request())

		if (!filters) filters = getState()[MODULE_NAME].gpsListViewFilters

		let ret = {}
		const req = { filters: filters ? filters : null }
		return postgrest
			.fetchAllWithPost('/rpc/gpss_filter_options', null, req, true)
			.then((response) => {
				if (response && response.length > 0) {
					ret.filterOptions = response[0].gpss_filter_options
				}
				return postgrest.fetchAllWithPost(FILTER_GPSS_ENDPOINT, null, req, true)
			})
			.then((response) => {
				ret.gpsListViewFilters = filters
				ret.analyses = response
				dispatch(receive(FETCHED_FILTER_OPTIONS_AND_ANALYSES, ret))
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

const create = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = cloneDeep(getState()[MODULE_NAME].pendingFields)
		let payload = {
			creation_context: 'gps',
			relator_groups: fields.relator_groups,
		}

		// Remove unnecessary properties
		delete fields.partition_selections
		delete fields.partitions
		delete fields.relator_groups
		fields['purchase_dataset_id'] = fields.selectedDataset
			? fields.selectedDataset['value']
			: null
		delete fields.selectedDataset

		payload = {
			...payload,
			data: fields,
		}

		return postgrest
			.create(GPSS_ENDPOINT, payload)
			.then((response) => {
				const tmpResp = response
				const data = response.data || {}
				const newFields = {
					...tmpResp,
					...data,
					only_with_recs: false,
					select_greatest_savings: false,
					selected_by_case: null,
					rec_price_calc: '',
					relator_job_complete: false,
					select_step_view_filters: [],
				}
				dispatch(receive(CREATED, newFields))
				return newFields
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

const save = (fields, pool = '') => {
	return (dispatch, getState) => {
		dispatch(request())

		const currentModel = getState()[MODULE_NAME].currentModel
		let tmpFields = cloneDeep(currentModel.fields)

		if (pool) {
			const update_endpoint =
				pool === POOL_ISO ? USAGE_FILTER_ENDPOINT : REC_FILTER_ENDPOINT
			return postgrest
				.save(
					update_endpoint,
					currentModel.id(),
					{
						filters: fields['filters'][pool],
					},
					null,
					'gps_id',
				)
				.then((response) => {
					// @todo temporary solution to make sure
					// filters are saved in gpss
					let dataObj = cloneDeep(tmpFields.data)
					dataObj = Object.assign(dataObj, {
						filters: fields['filters'],
					})

					return postgrest
						.save(GPSS_ENDPOINT, currentModel.id(), {
							data: dataObj,
						})
						.then((response) => {
							tmpFields = {
								...tmpFields,
								filters: fields['filters'],
								data: dataObj,
							}
							dispatch(receive(SAVED, tmpFields))
							return true
						})
						.catch((error) => {
							dispatch(fail(error))
							throw error
						})
				})
				.catch((error) => {
					dispatch(fail(error))
					throw error
				})
		} else {
			let fieldsCopy = cloneDeep(fields)
			let dataObj = cloneDeep(tmpFields.data)
			if (fieldsCopy.data) {
				dataObj = Object.assign(dataObj, fieldsCopy.data)
			}
			fieldsCopy = {
				...fieldsCopy,
				data: dataObj,
			}

			return postgrest
				.save(GPSS_ENDPOINT, currentModel.id(), fieldsCopy)
				.then((response) => {
					tmpFields = {
						...tmpFields,
						...fieldsCopy,
						...fieldsCopy.data,
					}
					dispatch(receive(SAVED, tmpFields))
					return true
				})
				.catch((error) => {
					dispatch(fail(error))
					throw error
				})
		}
	}
}

const destroy = (id) => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(GPSS_ENDPOINT, id)
			.then(() => {
				dispatch(receive(DESTROYED, id))
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

const createProductEdit = (caseNumber, isolatedId, recId, units, price) => {
	return (dispatch, getState) => {
		dispatch(request())

		const id = getState()[MODULE_NAME].currentModel.id()
		let endpoint = ''
		if (isolatedId && recId) {
			endpoint = '/gps_individual_price_edits'
		} else if (recId) {
			endpoint = '/gps_all_rec_instances_price_edits'
		} else if (units) {
			endpoint = '/gps_units_edits'
		} else {
			endpoint = '/gps_usage_price_edits'
		}
		const payload = {
			gps_id: id,
		}
		if (isolatedId) {
			payload.usage_product_number = isolatedId
		}
		if (recId) {
			payload.rec_product_number = recId
		}
		if (units) {
			payload.units = units
		}
		if (price) {
			payload.price = price
		}

		return postgrest
			.create(endpoint, payload, true)
			.then((response) => {
				dispatch({ type: PRODUCT_EDIT_CREATED })
				return true
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * getting collaborators by gps id
 */
const fetchCollaboratorsById = () => {
	return (dispatch, getState) => {
		const currentModel = getState()[MODULE_NAME].currentModel

		return postgrest
			.fetch(
				API.GUIDED_PURCHASE_GET_COLLABORATORS.PATH,
				currentModel.id(),
				'gps_id',
			)
			.then((response) => {
				const collaborators =
					response && response.collaborators ? response.collaborators : []
				dispatch(receive(COLLABORATOR_FETCHED, collaborators))
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateCollaborators = (collaborators) => {
	return (dispatch, getState) => {
		dispatch(receive(COLLABORATOR_FETCHED, collaborators))
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true,
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)
		return {
			...state,
			pendingFields: cloneDeep(fields),
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: cloneDeep(DEFAULT_FIELDS),
	}),
	[SET_DATA]: (state, { payload }) => ({
		...state,
		currentModel: new Model(Object.assign(state.currentModel.fields, payload)),
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true,
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new Collection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true,
	}),
	// TODO: remove later
	[FETCHED_FILTER_OPTIONS_AND_ANALYSES]: (state, { payload }) => {
		let newState = {
			...state,
			filterOptions: payload.filterOptions,
			gpsListViewFilters: cloneDeep(payload.gpsListViewFilters),
			results: new Collection(payload.analyses),
			fetchError: null,
			isFetching: false,
			hasFetched: true,
			page: payload.page,
			totalResults: payload.analyses ? payload.analyses.length : 0,
		}
		return newState
	},
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload,
	}),
	[PRODUCT_EDIT_CREATED]: (state) => ({
		...state,
		fetchError: null,
		isFetching: false,
	}),
	[CREATED]: (state, { payload }) => {
		const collection = state.results

		return {
			...state,
			currentModel: new Model(payload),
			results: new Collection([payload, ...collection.models]),
			pendingFields: cloneDeep(DEFAULT_FIELDS),
			fetchError: null,
			isFetching: false,
		}
	},
	[SAVED]: (state, { payload }) => {
		let collection = state.results
		const model = new Model(payload)
		collection = collection.update(model)

		return {
			...state,
			currentModel: model,
			results: new Collection([...collection.models]),
			fetchError: null,
			isFetching: false,
		}
	},
	[DESTROYED]: (state, { payload }) => {
		let collection = state.results
		collection = collection.remove([payload])

		return {
			...state,
			currentModel: new Model(DEFAULT_FIELDS),
			results: new Collection([...collection.models]),
			fetchError: null,
			isFetching: false,
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true,
	}),
	[COLLABORATOR_REQUESTED]: (state, action) => ({
		...state,
		isFetchingCollaborators: true,
	}),
	[COLLABORATOR_FETCHED]: (state, { payload }) => ({
		...state,
		collaborators: payload,
		fetchError: null,
		isFetchingCollaborators: false,
	}),
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchPage,
	fetchFilterOptionsAndAnalyses,
	createProductEdit,
	create,
	save,
	destroy,
	saveEdit,
	clearEdit,
	setData,
	reset,
	updateGPSListViewFilter,
	fetchCollaboratorsById,
	updateCollaborators,
}
