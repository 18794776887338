/**
 * postgREST service
 *
 * @see postgREST https://postgrest.com/en/v4.3/
 * @see superagent http://visionmedia.github.io/superagent/
 */

import request from 'superagent'
import { store } from 'state'
import { actions as alertActions } from 'state/modules/alerts'
import { actions as sessionActions } from 'state/modules/session'
import { ERROR as ALERT_ERROR } from 'data/alert-types'

const EXPIRED_SESSION_ERROR_MESSAGE = 'Session expired. Please log in again.'
const API_BASE_URL = window.env.POSTGREST_ENDPOINT
const AUTH_HEADER_PRE = 'Bearer '

export function createUrl(endpoint) {
	return `${API_BASE_URL}${endpoint}`
}

export function createAuthHeader(needsAuth) {
	return needsAuth
		? `${AUTH_HEADER_PRE}${store.getState().session.credentials.token}`
		: null
}

export function handleErrors(response) {
	// unauthorized. log user out!
	if (response && response.statusCode === 401) {
		store.dispatch(sessionActions.logout())
		setTimeout(() => {
			store.dispatch(
				alertActions.showAlert({
					message: EXPIRED_SESSION_ERROR_MESSAGE,
					type: ALERT_ERROR,
					timeout: 6000,
				}),
			)
		}, 500)
		throw Error(response.statusText)
	} else if (!response.ok) {
		throw Error(response.statusText)
	}
	return response
}

export function fetch(
	endpoint,
	queryVal,
	queryKey = 'id',
	querySelect,
	auth = true,
) {
	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query({ [queryKey]: `eq.${queryVal}`, select: querySelect })
			.then(handleErrors)
			.then((response) => {
				if (!response.body || !response.body.length) {
					resolve({})
				} else {
					resolve(response.body[0])
				}
			})
			.catch((error) => reject(error))
	})
}

export function fetchWithCustomQuery(endpoint, query, auth = true) {
	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query(query)
			.then(handleErrors)
			.then((response) => {
				if (!response.body || !response.body.length) {
					resolve([])
					// throw new Error('No resource(s) found.')
				} else {
					resolve(response.body)
				}
			})
			.catch((error) => reject(error))
	})
}

export function fetchAll(endpoint, queryVal, queryKey = 'id', auth = true) {
	let query = null

	if (queryVal) {
		query = { [queryKey]: `eq.${queryVal}` }
	}

	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query(query)
			.then(handleErrors)
			.then((response) => {
				resolve(response.body)
			})
			.catch((error) => reject(error))
	})
}

export function fetchList(
	endpoint,
	queryVals,
	queryKey = 'id',
	select,
	auth = true,
) {
	let query = {}

	if (queryVals) {
		query = { [queryKey]: `in.(${queryVals})` }
	}

	if (select) {
		query['select'] = select
	}

	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query(query)
			.then(handleErrors)
			.then((response) => {
				resolve(response.body)
			})
			.catch((error) => reject(error))
	})
}

// used for RPC endpoints
export function fetchAllWithPost(
	endpoint,
	query,
	payload,
	auth = true,
	single = false,
	jsonParam = false,
	isCancellable = false,
) {
	let req = null
	let promise = null
	promise = new Promise((resolve, reject) => {
		req = request
			.post(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query(query)

		if (single) {
			req.set('Accept', 'application/vnd.pgrst.object+json')
		}
		if (jsonParam) {
			req.set('Prefer', 'params=single-object')
		}

		req
			.send(payload)
			.then(handleErrors)
			.then((response) => {
				resolve(response.body)
			})
			.catch((error) => reject(error))
	})
	// if need to cancel the request, return Superagent request object
	if (isCancellable) {
		const reqObj = {
			promise: promise,
			req: req,
		}
		return reqObj
	} else {
		return promise
	}
}

export function fetchPage(endpoint, page, perPage, query, auth = true) {
	const rangeStart = page * perPage
	const rangeEnd = rangeStart + perPage - 1 // to account for starting at zero
	let tmpQuery = query || {}
	tmpQuery['limit'] = perPage
	tmpQuery['offset'] = rangeStart

	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.set('Range-Unit', 'items')
			.set('Range', `${rangeStart}-${rangeEnd}`)
			.set('Prefer', 'count=exact')
			.query(tmpQuery)
			.then(handleErrors)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => reject(error))
	})
}

export function fetchPageWithPost(
	endpoint,
	page,
	perPage,
	query,
	payload,
	auth = true,
) {
	const rangeStart = page * perPage
	const rangeEnd = rangeStart + perPage - 1 // to account for starting at zero
	let tmpQuery = query || {}
	tmpQuery['limit'] = perPage
	tmpQuery['offset'] = rangeStart

	return new Promise((resolve, reject) => {
		request
			.post(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.set('Range-Unit', 'items')
			.set('Range', `${rangeStart}-${rangeEnd}`)
			.set('Prefer', 'count=exact')
			.query(tmpQuery)
			.send(payload)
			.then(handleErrors)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => reject(error))
	})
}

export function create(endpoint, payload, auth = true) {
	return new Promise((resolve, reject) => {
		request
			.post(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.set('Prefer', 'return=representation') // return created resource
			.send(payload)
			.then(handleErrors)
			.then((response) => {
				if (response.body) {
					if (
						!response.body.length ||
						(response.body.length && response.body.length > 1)
					) {
						resolve(response.body)
					} else {
						resolve(response.body[0])
					}
				} else {
					resolve('ok')
				}
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function createMinimal(endpoint, payload, auth = true) {
	return new Promise((resolve, reject) => {
		request
			.post(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.set('Prefer', 'return=minimal')
			.send(payload)
			.then(handleErrors)
			.then((response) => {
				resolve()
			})
			.catch((error) => reject(error))
	})
}

export function save(
	endpoint,
	id,
	payload,
	customQuery,
	key = 'id',
	auth = true,
) {
	let query = null

	if (customQuery) {
		query = customQuery
	} else if (id) {
		query = { [key]: `eq.${id}` }
	}

	return new Promise((resolve, reject) => {
		request
			.patch(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.set('Prefer', 'return=representation') // return created resource
			.query(query)
			.send(payload)
			.then(handleErrors)
			.then((response) => {
				if (response.body && response.body.length) {
					resolve(response.body[0])
				} else {
					resolve(response.body)
				}
			})
			.catch((error) => reject(error))
	})
}

export function destroy(
	endpoint,
	queryVal,
	queryKey = 'id',
	customQuery,
	auth = true,
) {
	let query = null

	if (customQuery) {
		query = customQuery
	} else if (queryVal) {
		query = { [queryKey]: `eq.${queryVal}` }
	}

	return new Promise((resolve, reject) => {
		request
			.delete(createUrl(endpoint))
			.set('Authorization', createAuthHeader(auth))
			.query(query)
			.then(handleErrors)
			.then((response) => {
				resolve()
			})
			.catch((error) => reject(error))
	})
}
